@import './css/colors.css';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.greyBg {
  background-color: #d5d7e1;
}
.beigeBg {
  background-color: #e1d7d5;
}
