.message {
  margin-top: 4em;
}
.message p {
  color: white;
  font-family: 'Londrina Solid';
  font-size: 2rem;
  text-align: left;
  padding-top: 0px;
  padding-bottom: 10px;
  margin-bottom: 0px;
}