
.RibbonIcon {
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  /* Similar syntax to box-shadow */
  height: 75px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;
}

.mintBtn {
  font-family: 'Londrina Solid';
  color: white;
  padding: 1rem 1rem;
  font-size: 2rem;
  width: 150px;
  height: 150px;
}


.mintBtn:hover, .mintBtn:focus, .mintBtn:active, .mintBtn.active, .open>.dropdown-toggle.mintBtn {
    font-family: 'Londrina Solid';
    color: #fff;
    background-color: #460046;
    border-color: purple; /*set the color you want here*/
}

.btn3d {
    position: relative;
    top: -6px;
    border: 0;
    transition: all 40ms linear;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
    -moz-outline-style: none;
    outline: medium none;
}

.btn3d:active,
.btn3d.active {
    top: 2px;
}

.btn3d.btnDanger {
    box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #AA0000, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    background-color: #D73814;
}
.btn3d.btnDanger:active,
.btn3d.btnDanger.active {
    box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: #D73814;
}

.addressInput {
    text-align:center;
    width: 60%;
}
.addressPlaceholder {
    width: 100%;
}

.title {
    color: red;
    font-family: 'Londrina Solid';
    font-size: 5rem;
    text-align: center;
    margin: 0.1em;
}

.snowflakes {
    z-index: -1;
}
.snowflake {
    color: rgb(255, 0, 0);
    font-size: 2em;
    font-family: Arial;
    text-shadow: 0 0 1px #000;
    z-index: -1;
}



.gift {
    margin: auto;
}

.bitstrayContentCol {
    display: flex;
}

.bitstrayWrapper {
    align-self: flex-end;
    width: 100%;
}

/* @-webkit-keyframes snowflakes-fall{
    0% {top:-10%}
    100%{top:100%}
}
@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}
  
@media screen and (max-width: 1200px) {
  .btn3d {
    font-size: 2.8em;
  }
} */
