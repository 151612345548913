.TotalSupplyLabel {
  font-family: 'Londrina Solid';
  font-size: 2rem;
}

.mintProgress {
  height: 1.5rem;
  margin-top: 15px;
}

.mintProgressSupply {
  font-size: medium;
  color: grey;
  position: relative;
  top: -24px;
  left: 38%;
}
