.headerWrapper h1 {
    font-family: 'Londrina Solid';
    font-size: 4rem;
  }
  .headerWrapper p {
    margin: 1rem 1rem;
  }
  
  @media (max-width: 992px) {
    .headerWrapper {
      padding: 1rem;
    }
  }
  
  .card {
    border: none !important;
    border-radius: 0 !important;
    margin-bottom: 2.5rem;
    max-width: fit-content;
  }
  
  .twitter {
    margin-bottom: 0.4rem;
  }
  .cardHeader:hover {
    color: var(--brand-dark-red);
  }
  
  .card,
  .headerWrapper {
    font-size: 1.3rem;
  }
  .cardHeader {
    border: none;
    background-color: transparent;
    font-family: 'Londrina Solid';
    font-size: 2.5rem;
    cursor: pointer;
    line-height: normal;
    padding-top: 0;
    padding-bottom: 0;
  }
  