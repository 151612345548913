.modal {
  position: fixed;
  top: 15vh;
  z-index: 100;
  padding: 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 12.5rem);
  width: 25rem;
}

.displayBitstrayFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.displayBitstrayFooter span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.displayBitstrayFooter button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.displayBitstrayFooter button:hover,
.displayBitstrayFooter button:active,
.displayBitstrayFooter button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.bitstrayImg {
  border-radius: 16px;
}
.bitstrayWrapper {
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .modal {
    width: 80% !important;
    left: 10% !important;
  }
}
